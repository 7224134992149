import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "OrganisationUsersTable" }
const _hoisted_2 = { class: "UsersTableEditUserForm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDetailsBlock = _resolveComponent("UserDetailsBlock")!
  const _component_UserDetailsForm = _resolveComponent("UserDetailsForm")!
  const _component_DeleteDetachUserBlock = _resolveComponent("DeleteDetachUserBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "topGrid",
      class: _normalizeClass([
				'UsersTable',
				'ag-theme-material',
				_ctx.topOrganisationUsers.length ? 'UsersTableWithContent' : ''
			])
    }, null, 2 /* CLASS */),
    _createVNode(_Transition, { persisted: "" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UserDetailsBlock, { user: _ctx.detailsOfSelectedUser }, null, 8 /* PROPS */, ["user"]),
          _createVNode(_component_UserDetailsForm, {
            isVisible: _ctx.selectedUserForDisplayPurposes !== 0,
            isEdit: true,
            user: _ctx.detailsOfSelectedUser,
            onFormClosed: _ctx.resetSelectedUser
          }, null, 8 /* PROPS */, ["isVisible", "user", "onFormClosed"]),
          _createVNode(_component_DeleteDetachUserBlock)
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.selectedUserAsStored]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.shouldSecondTableBeShown ? '' : 'display: none;'),
      ref: "bottomGrid",
      class: _normalizeClass([
				'UsersTable',
				'ag-theme-material',
				_ctx.bottomOrganisationUsers.length ? 'UsersTableWithContent' : ''
			])
    }, null, 6 /* CLASS, STYLE */)
  ]))
}