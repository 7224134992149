<template>
	<div class="UserDetailsBlock">
		<div class="UserDetailsBlockColumn">
			<div class="UserDetailsBlockTitle">
				{{ selectedUserName }}
			</div>
			<div
				v-if="selectedUserRole"
				class="UserDetailsBlockRow"
			>
				<label class="UserDetailsBlockLabel">
					Role:
				</label>
				{{ selectedUserRole }}
			</div>
			<div class="UserDetailsBlockRow">
				<label class="UserDetailsBlockLabel">
					Products:
				</label>
				<div
					v-for="tag in productTags"
					:key="tag"
					class="CustomComponentTag"
				>
					{{ tag }}
				</div>
			</div>
			<div class="UserDetailsBlockRow">
				<label class="UserDetailsBlockLabel">
					Email:
				</label>
				{{ selectedUserEmail }}
			</div>
		</div>
		<div class="UserDetailsBlockColumn">
			<div class="UserDetailsBlockRow">
				<label class="UserDetailsBlockLabel LastLoginLabel">
					Last login:
				</label>
				<span
					class="UserDetailsBlockValue"
					:title="lastLoginDateStringLong"
				>
					{{ lastLoginDateStringShort }}
				</span>
				<i
					class="UserDetailsBlockIcon UserDetailsBlockEditButton lnr-pencil"
					@click="closeUserDetailsForm"
				></i>
				<i class="UserDetailsBlockIcon UserDetailsBlockDisabledIcon lnr-trash2"></i>
				<i class="UserDetailsBlockIcon UserDetailsBlockDisabledIcon lnr-unlink"></i>
			</div>
		</div>
	</div>
</template>

<style lang="scss" src="./user-details-block.scss"></style>
<script lang="ts" src="./user-details-block.ts"></script>
