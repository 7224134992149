<template>
	<div
		ref="modalContent"
		class="ConfirmationModal"
	>
		<div class="ConfirmationText">
			Are you sure you want to delete {{ nameOfSelectedUser }}?
		</div>
		<button
			type="button"
			class="DriverButton"
			:disabled="confirmationProcessActive"
			@click="deleteUser"
		>
			Delete
		</button>
		<button
			type="button"
			class="HelperButton UsersManagementHelperButton"
			@click="closeModal"
		>
			Cancel
		</button>
	</div>
</template>

<script lang="ts" src="./delete-confirmation-modal.ts"></script>
<style lang="scss" src="./delete-confirmation-modal.scss"></style>
