import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { computed } from 'vue';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export default class CustomComponentCampaigns implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;

	private user = computed((): DetailedUserDto | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as DetailedUserDto;
	});

	private get domainUrl(): string {
		const host = window.location.host;
		if (!host.includes('administration')) {
			return 'https://administration.studyportals.eu';
		}

		return `https://${host}`;
	}

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.outerWrapper.className = 'CustomComponentTagWrapper';
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		this.addCampaignTags();
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addCampaignTags(): void {
		const user = this.user.value;
		if (!user) {
			return;
		}

		for (const id of user.activeCampaigns) {
			const link = document.createElement('a');
			link.href = `${this.domainUrl}/statistics/campaign/${id}/edit.html`;
			const elementId = document.createElement('p');
			link.className = 'CustomComponentTag';
			link.innerHTML = id.toString();
			elementId.appendChild(link);
			this.outerWrapper.appendChild(elementId);
		}
	}
}
