import { defineComponent, toRef } from 'vue';
import AccordionCode from '@/presentation/components/accordion/accordion.code';

export default defineComponent({
	props: {
		isOpen: { type: Boolean, required: true },
		buttonText: { type: String, required: true }
	},
	setup: (props, context) => {
		const isOpen = toRef(props, 'isOpen');
		const code = new AccordionCode(context.emit);

		return {
			isAccordionOpen: isOpen,
			buttonText: props.buttonText,
			toggleAccordion: code.toggleAccordion.bind(code)
		};
	}
});
