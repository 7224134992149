import { Component, defineComponent, onBeforeMount } from 'vue';
import InitialRoutePresenter from '@/presenters/initial-route-presenter';
import LogOut from '@/stand-alone/log-out/log-out.vue';
import ErrorModal from '@/presentation/components/error-modal/error-modal.vue';
import AppCode from '@/presentation/app.code';

export default defineComponent({
	components: {
		LogOut: LogOut as Component,
		ErrorModal: ErrorModal as Component
	},
	setup: () => {
		const code = new AppCode();

		onBeforeMount(() => InitialRoutePresenter.prepareForRoutingActions());

		return {
			CurrentComponent: code.CurrentComponent,
			router: code.router,
			standAloneLogin: code.standAloneLogin,
			errorActive: code.errorActive
		};
	}
});
