import { App } from '@vue/runtime-core';

class RollbarOperator {
	ignoredWarnings: string[] = [];
	ignoredErrors: string[] = [];

	public handleVueErrorsAndWarnings(app: App): void {
		this.handleVueErrors(app);
		this.handleVueWarnings(app);
		this.handleGlobalErrors();
		this.handleGlobalWarnings();
	}

	public triggerError(message: Error | string): void {
		console.error(message);

		if (!window.Rollbar) {
			return;
		}

		window.Rollbar.error(message);
	}

	public triggerWarning(message: Error | string): void {
		console.warn(message);

		if (!window.Rollbar) {
			return;
		}

		window.Rollbar.warn(message);
	}

	private handleVueErrors(app: App): void {
		app.config.errorHandler = (error): void => {
			this.triggerError(error as Error);
			throw error;
		};
	}

	private handleVueWarnings(app: App): void {
		app.config.warnHandler = (warning): void => {
			this.triggerWarning(warning);
		};
	}

	private handleGlobalErrors(): void {
		const originalConsoleError = console.error;
		console.error = (message): void => {
			if (this.ignoredErrors.includes(message as string)) {
				return;
			}

			originalConsoleError(message);
		};
	}

	private handleGlobalWarnings(): void {
		const originalConsoleWarn = console.warn;
		console.warn = (message): void => {
			if (this.ignoredWarnings.includes(message as string)) {
				return;
			}

			originalConsoleWarn(message);
		};
	}
}

export default new RollbarOperator();
