import { defineComponent, Component, toRef } from 'vue';
import { SearchDropdownTypes } from '@/models/search.interface';
import { SearchUserDto } from '@studyportals/campaign-management-api-interface/src/domain/users/search-users.dto';
import SearchDropdownItem from '@/presentation/components/search-input/dropdown-item/search-dropdown-item.vue';
import SearchDropdownCode from '@/presentation/components/search-input/dropdown/search-dropdown.code';
import Spinner from '@/presentation/components/spinner/spinner.vue';

export default defineComponent({
	props: {
		searchTerm: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: true
		},
		showSpinner: {
			type: Boolean,
			required: true
		},
		type: {
			type: String,
			required: true
		}
	},
	components: {
		SearchDropdownItem: SearchDropdownItem as Component,
		Spinner: Spinner as Component
	},
	setup: (props, context) => {
		const code = new SearchDropdownCode(context.emit, props.type as SearchDropdownTypes);

		const searchTerm = toRef(props, 'searchTerm');

		return {
			searchTerm,
			notFoundText: code.notFoundText,
			items: props.items as SearchUserDto[],
			userClick: code.onUserClick.bind(code)
		};
	}
});
