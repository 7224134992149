import { defineComponent, Component, onMounted, onUnmounted } from 'vue';
import { SearchDropdownTypes } from '@/models/search.interface';
import SearchDropdown from '@/presentation/components/search-input/dropdown/search-dropdown.vue';
import SearchInputCode from '@/presentation/components/search-input/search-input.code';

export default defineComponent({
	components: {
		SearchDropdown: SearchDropdown as Component
	},
	props: {
		type: { type: String, required: true }
	},
	setup: (props, context) => {
		const code = new SearchInputCode(
			props.type as SearchDropdownTypes,
			context.emit
		);

		onMounted(code.mounted.bind(code));
		onUnmounted(code.unmounted.bind(code));

		return {
			placeholder: code.placeholder,
			isSearchTypeOrganisation: code.isSearchTypeOrganisation,
			isSearchTypeUser: code.isSearchTypeUser,
			isSearchTypeCombinedSearch: code.isSearchTypeCombinedSearch,
			type: props.type,
			searchResultsAsString: code.searchResultsAsString,
			searchWrapper: code.searchWrapper,
			searchTerm: code.searchTerm,
			searchInput: code.searchInput,
			searchResults: code.searchResults,
			showSpinner: code.showSpinner,
			showDropdown: code.showDropdown,
			setSearchTerm: code.setSearchTerm.bind(code),
			onInputFocus: code.onInputFocus.bind(code),
			onUserClick: code.onUserClick.bind(code)
		};
	}
});
