import { Component, computed } from 'vue';
import { IRouter, Routes, TRoutes } from '@/models/router/router.interface';
import { ModalErrorStates } from '@/models/modal/modal.enum';
import UserActions from '@/presentation/pages/user-actions/user-actions.vue';
import Organisation from '@/presentation/pages/organisation/organisation.vue';
import store from '@/store';

export default class AppCode {
	private routes: TRoutes = {
		[Routes.USER_ACTIONS]: UserActions as Component,
		[Routes.ORGANISATION]: Organisation as Component
	};

	public standAloneLogin = computed((): boolean => {
		return store.getters.standAloneLogin();
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public CurrentComponent = computed((): Component => {
		return this.routes[this.router.value.current];
	});

	public router = computed((): IRouter => {
		return store.getters.router();
	});

	public errorActive = computed((): boolean => {
		return this.modalErrorState.value !== ModalErrorStates.INACTIVE;
	});

	private modalErrorState = computed((): ModalErrorStates => {
		return store.getters.modalErrorState();
	});
}
