interface IUserContactDetailsUpdateEvent {
	target?: {
		value: string;
	};
}

enum UserContactDetailsTypes {
	TEXT = 'text',
	EMAIL = 'email'
}

interface IUserContactDetails {
	label: string;
	value: string;
	type: UserContactDetailsTypes;
	elementId: string;
	required: boolean;
	updateMethod: (event: IUserContactDetailsUpdateEvent) => void;
}

interface IUserRightsDetails {
	labelChecked: string;
	labelUnchecked: string;
	value: boolean;
	toggleMethod: () => void;
}

export { IUserContactDetails, IUserRightsDetails, IUserContactDetailsUpdateEvent, UserContactDetailsTypes };
