import { computed, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { ModalErrorStates } from '@/models/modal/modal.enum';
import store from '@/store';

export default class ErrorModalCode {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public errorActive = computed((): boolean => {
		return this.modalErrorState.value !== ModalErrorStates.INACTIVE;
	});

	public errorIsRelatedToOrganisationDetails = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.LOADING_ORGANISATION_DETAILS;
	});

	public errorIsRelatedToOrganisationUsers = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.LOADING_ORGANISATION_USERS;
	});

	public errorIsRelatedToCreateNewUser = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.CREATE_NEW_USER;
	});

	public errorIsRelatedToUpdateExistingUser = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.UPDATE_EXISTING_USER;
	});

	public errorIsRelatedToTriggerOnboardingFlow = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.TRIGGER_ONBOARDING_FLOW;
	});

	public errorIsRelatedToSearchUsers = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.SEARCH_USERS;
	});

	public errorIsRelatedToAttachUserToOrganisation = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.ATTACH_USER_TO_ORGANISATION;
	});

	public errorIsRelatedToDeleteUser = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.DELETE_USER;
	});

	public errorIsRelatedToDetachUser = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.DETACH_USER;
	});

	public errorIsRelatedToSearchOrganisations = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.SEARCH_ORGANISATIONS;
	});

	public errorIsRelatedToCombinedSearchUsers = computed((): boolean => {
		return this.modalErrorState.value === ModalErrorStates.COMBINED_SEARCH_USERS;
	});

	private modalErrorState = computed((): ModalErrorStates => {
		return store.getters.modalErrorState();
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'ErrorModalWrapper',
			destroyOnClose: false,
			onClose: (): void => store.mutations.setModalErrorState(ModalErrorStates.INACTIVE)
		});

		window.ModalManager.open(this.modal.value);
	}
}
