import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { computed } from 'vue';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { ProductTypes } from '@/models/table.interface';
import userProductLabelsPresenter from '@/presenters/user-product-labels-presenter';

export default class CustomComponentProducts implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;

	private user = computed((): DetailedUserDto | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as DetailedUserDto;
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.outerWrapper.className = 'CustomComponentTagWrapper';
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		this.addProductTags();
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addProductTags(): void {
		const user = this.user.value;
		if (!user) {
			return;
		}

		const productLabels = userProductLabelsPresenter.productLabels;
		for (const productType in productLabels) {
			if (!user[productType as ProductTypes]) {
				continue;
			}

			const elementName = document.createElement('p');
			elementName.className = 'CustomComponentTag';
			elementName.innerHTML = productLabels[productType as ProductTypes];
			this.outerWrapper.appendChild(elementName);
		}
	}
}
