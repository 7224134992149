import { defineComponent } from 'vue';
import DeleteDetachUserBlockCode from '@/presentation/components/delete-detach-user-block/delete-detach-user-block.code';

export default defineComponent({
	setup: () => {
		const code = new DeleteDetachUserBlockCode();

		return {
			detachProcessActive: code.detachProcessActive,
			deleteUser: code.deleteUser.bind(code),
			detachUser: code.detachUser.bind(code)
		};
	}
});
