import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { computed } from 'vue';

export default class CustomComponentNameTooltip implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;

	private user = computed((): DetailedUserDto | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as DetailedUserDto;
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		// If this is a header cell, simply show the name on hover.
		if (!this.user.value) {
			const element = document.createElement('span');
			element.innerHTML = this.params.value as string;
			this.outerWrapper.appendChild(element);
			return;
		}

		this.addContent();
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addContent(): void {
		const elementName = document.createElement('span');
		elementName.innerHTML = `Name: ${this.user.value?.fullName || ''}`;
		const elementEmail = document.createElement('span');
		elementEmail.innerHTML = `Email: ${this.user.value?.email || ''}`;

		this.outerWrapper.appendChild(elementName);
		this.outerWrapper.appendChild(elementEmail);
	}
}
