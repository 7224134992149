import { ref } from 'vue';
import { ModalConfirmationStates } from '@/models/modal/modal.enum';
import userPresenter from '@/presenters/user-presenter';
import store from '@/store';

export default class DeleteDetachUserBlockCode {
	public detachProcessActive = ref(false);

	public deleteUser(): void {
		store.mutations.setModalConfirmationState(ModalConfirmationStates.DELETE_USER);
	}

	public async detachUser(): Promise<void> {
		this.detachProcessActive.value = true;
		await userPresenter.detachUserFromOrganisation();
		this.detachProcessActive.value = false;
	}
}
