<template>
	<div class="UserSearchDropdownContainer">
		<div
			v-if="showSpinner"
			class="SpinnerContainer"
		>
			<Spinner />
		</div>

		<template v-else>
			<p
				v-if="!items.length && searchTerm"
				class="NotFoundContainer"
			>
				{{ notFoundText }}
			</p>
			<template
				v-else
				v-for="item in items"
				:key="item.id"
			>
				<SearchDropdownItem
					:name="item.name"
					:linkId="item.linkId"
					:type="item.type"
					:primarySubtitle="item.primarySubtitle"
					:secondarySubtitle="item.secondarySubtitle"
					@userClick="userClick"
				/>
			</template>
		</template>
	</div>
</template>

<script lang="ts" src="./search-dropdown.ts" />
<style lang="scss" src="./search-dropdown.scss" />
