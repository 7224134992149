import state from './state';
import { ACCORDION_ANIMATION_DURATION } from '@/static/timing';
import { IMutations } from '@/models/store/mutations.interface';

const mutations: IMutations = {
	setCurrentRoute: (name, id) => {
		state.router.current = name;

		// Maintain a url format which uses a hash to separate the universityAdmin url part from the microservice url part.
		let url = `${window.location.origin}/users-management/#/${name}`;
		if (id) {
			url += `?id=${id}`;
		}

		// Keep the url in the address bar up-to-date.
		history.pushState({}, '', url);

		state.router.props.id = id ? id : 0;
	},
	setModalConfirmationState: (confirmationState) => {
		state.modalConfirmationState = confirmationState;
	},
	setModalErrorState: (errorState) => {
		state.modalErrorState = errorState;
	},
	setOrganisationDetails: (organisationDetails) => {
		state.organisationDetails = organisationDetails;
	},
	setOrganisationSuccessMessage: (message) => {
		state.organisationSuccessMessage = message;
	},
	setOrganisationUsers: (organisationUsers) => {
		// Force the store to update by creating a new array.
		state.organisationUsers = [...organisationUsers];
		state.organisationUsersSorted = [...organisationUsers];
	},
	setSelectedUser: (selectedUser) => {
		if (state.selectedUser === 0 || selectedUser === 0) {
			state.selectedUser = selectedUser;
			return;
		}

		// When switching between two users, first make sure the accordion is closed before opening it for a different user.
		state.selectedUser = 0;

		setTimeout(() => {
			state.selectedUser = selectedUser;
		}, ACCORDION_ANIMATION_DURATION + 1);
	},
	setStandAloneLogin: (standAloneLogin) => {
		state.standAloneLogin = standAloneLogin;
	},
	updateOrganisationUsersSorted: (sortedOrganisationUsers) => {
		// Force the store to update by creating a new array.
		state.organisationUsersSorted = [...sortedOrganisationUsers];
	}
};

export default mutations;
