import UserSession from '@/infrastructure/user-session';

export default class Bootstrapper {
	public static async bootstrap(): Promise<void> {
		if (!UserSession.isValid()) {
			const error = new Error('No valid session found');
			error.name = 'NotAuthorizedException';
			throw error;
		}

		await UserSession.acquireCredentials();
	}
}
