import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "UAUsersManagementFrontEnd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorModal = _resolveComponent("ErrorModal")!
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CurrentComponent))),
    (_ctx.errorActive)
      ? (_openBlock(), _createBlock(_component_ErrorModal, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.standAloneLogin)
      ? (_openBlock(), _createBlock(_component_LogOut, { key: 1 }))
      : _createCommentVNode("v-if", true)
  ]))
}