<template>
	<div id="UAUsersManagementFrontEnd">
		<component :is="CurrentComponent" />

		<ErrorModal v-if="errorActive" />
		<LogOut v-if="standAloneLogin" />
	</div>
</template>

<script lang="ts" src="./app.ts"></script>
<style lang="scss" src="./styles/main.scss"></style>
