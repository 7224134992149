<template>
	<div
		v-if="organisationName"
		class="UsersManagementContentBlock"
	>
		<h2 class="UsersManagementTitle">
			{{ organisationName }}
		</h2>
		<label class="UsersManagementLabel">
			Organisation ID
		</label>
		<div class="UsersManagementSubtitle">
			{{ organisationId }}
		</div>
		<div
			v-if="organisationSuccessMessage"
			class="UsersManagementSuccessMessage"
		>
			<i class="lnr-checkmark-circle UsersManagementSuccessMessageIcon"></i>
			<span
				v-html="organisationSuccessMessage"
				class="UsersManagementSuccessMessageText"
			></span>
		</div>
	</div>
</template>

<style lang="scss" src="./organisation-details-block.scss"></style>
<script lang="ts" src="./organisation-details-block.ts"></script>
