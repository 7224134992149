import state from './state';
import { IGetters } from '@/models/store/state.interface';

const getters: IGetters = {
	modalConfirmationState: () => {
		return state.modalConfirmationState;
	},
	modalErrorState: () => {
		return state.modalErrorState;
	},
	organisationDetails: () => {
		return state.organisationDetails;
	},
	organisationSuccessMessage: () => {
		return state.organisationSuccessMessage;
	},
	organisationUsers: () => {
		return state.organisationUsers;
	},
	organisationUsersSorted: () => {
		return state.organisationUsersSorted;
	},
	router: () => {
		return state.router;
	},
	selectedUser: () => {
		return state.selectedUser;
	},
	standAloneLogin: () => {
		return state.standAloneLogin;
	}
};

export default getters;
