import { defineComponent } from 'vue';
import LogInCode from '@/stand-alone/log-in/log-in.code';

export default defineComponent({
	setup: () => {
		const code = new LogInCode();

		return {
			signInInternal: code.signInInternal.bind(code)
		};
	}
});
