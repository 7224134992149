import { Component, defineComponent } from 'vue';
import Accordion from '@/presentation/components/accordion/accordion.vue';
import SearchInput from '@/presentation/components/search-input/search-input.vue';
import UserDetailsForm from '@/presentation/components/user-details-form/user-details-form.vue';
import UserActionsCode from '@/presentation/pages/user-actions/user-actions.code';

export default defineComponent({
	components: {
		Accordion: Accordion as Component,
		UserDetailsForm: UserDetailsForm as Component,
		SearchInput: SearchInput as Component
	},
	setup: () => {
		const code = new UserActionsCode();
		return {
			searchTypeCombinedSearch: code.searchTypeCombinedSearch,
			accordionSelectionOptionFindOrEditUser: code.accordionSelectionOptionFindOrEditUser,
			accordionSelectionOptionAddNewUser: code.accordionSelectionOptionAddNewUser,
			isFindOrEditUserAccordionOpen: code.isFindOrEditUserAccordionOpen,
			isAddNewUserAccordionOpen: code.isAddNewUserAccordionOpen,
			setSelectedOption: code.setSelectedOption.bind(code),
			navigateToOrganisationPage: code.navigateToOrganisationPage.bind(code)
		};
	}
});
