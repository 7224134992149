import { Component, defineComponent, onMounted, Ref, toRef, watch } from 'vue';
import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import SearchInput from '@/presentation/components/search-input/search-input.vue';
import UserDetailsFormCode from '@/presentation/components/user-details-form/user-details-form.code';

export default defineComponent({
	components: {
		SearchInput: SearchInput as Component
	},
	props: {
		isVisible: { type: Boolean, required: true },
		isEdit: { type: Boolean, required: true },
		user: { type: Object, required: false }
	},
	setup: (props, context) => {
		const isVisible = toRef(props, 'isVisible');
		const user = toRef(props, 'user');
		const code = new UserDetailsFormCode(
			context.emit,
			props.isEdit,
			user as Ref<DetailedUserDto | undefined>
		);

		onMounted(code.updateFormValues.bind(code));
		watch(isVisible, code.onFormVisibleUpdateFormValues.bind(code));

		return {
			searchTypeOrganisation: code.searchTypeOrganisation,
			isEdit: props.isEdit,
			resetCount: code.resetCount,
			validationMessage: code.validationMessage,
			isSubmitDisabled: code.isSubmitDisabled,
			contactDetailsProperties: code.contactDetailsProperties,
			userRightsProperties: code.userRightsProperties,
			nameOfSelectedOrganisation: code.nameOfSelectedOrganisation,
			shouldOrganisationSelectorBeShown: code.shouldOrganisationSelectorBeShown,
			saveUser: code.saveUser.bind(code),
			updateFormValues: code.updateFormValues.bind(code),
			setSelectedOrganisation: code.setSelectedOrganisation.bind(code)
		};
	}
});
