import { EmitOptions } from '@/models/emit-options.enum';
import { SearchUserDto } from '@studyportals/campaign-management-api-interface/src/domain/users/search-users.dto';
import { MinimalOrganisationDto } from '@studyportals/campaign-management-api-interface';
import { SearchResultTypes } from '@/models/search.interface';

export default class SearchDropdownItemCode {
	constructor(
		private emit: (eventName: string, selectedItem: SearchUserDto | MinimalOrganisationDto) => void,
		private readonly type: SearchResultTypes,
		private readonly name: string,
		private readonly linkId: number | undefined = undefined,
		private readonly primarySubtitle: string | undefined = undefined
	) { }

	public get isTypeUniversityForCombinedSearch(): boolean {
		return this.type === SearchResultTypes.ORGANISATION_FOR_COMBINED_SEARCH;
	}

	public get isTypeUnlinkedUserForCombinedSearch(): boolean {
		return this.type === SearchResultTypes.UNLINKED_USER_FOR_COMBINED_SEARCH;
	}

	public onClick(): void {
		if (!this.linkId) {
			return;
		}

		this.emit(EmitOptions.USER_CLICK, {
			id: this.linkId,
			name: this.name,
			email: this.primarySubtitle
		});
	}
}
