import { computed, Ref } from 'vue';
import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { ProductTypes } from '@/models/table.interface';
import userProductLabelsPresenter from '@/presenters/user-product-labels-presenter';
import store from '@/store';
import userDatePresenter from '@/presenters/user-date-presenter';

export default class UserDetailsBlockCode {
	public selectedUserName = computed((): string => {
		return this.user.value?.fullName || '';
	});

	public selectedUserRole = computed((): string => {
		return this.user.value?.role || '';
	});

	public selectedUserEmail = computed((): string => {
		return this.user.value?.email || '';
	});

	public productTags = computed((): string[] => {
		const result: string[] = [];
		if (!this.user.value) {
			return result;
		}

		const productLabels = userProductLabelsPresenter.productLabels;
		for (const productType in productLabels) {
			if (!this.user.value[productType as ProductTypes]) {
				continue;
			}

			result.push(productLabels[productType as ProductTypes]);
		}

		return result;
	});

	public lastLoginDateStringLong = computed((): string => {
		return userDatePresenter.retrieveLastLoginDateStringForUser(this.user.value, false);
	});

	public lastLoginDateStringShort = computed((): string => {
		return userDatePresenter.retrieveLastLoginDateStringForUser(this.user.value, true);
	});

	constructor(private user: Ref<DetailedUserDto | undefined>) {}

	public closeUserDetailsForm(): void {
		store.mutations.setSelectedUser(0);
	}
}
