<template>
	<div class="DeleteDetachUserBlock">
		<button
			type="button"
			class="HelperButton UsersManagementHelperButton ButtonDeleteUser"
			@click="deleteUser"
		>
			Delete user
		</button>
		<button
			type="button"
			class="HelperButton UsersManagementHelperButton ButtonDetachUser"
			:disabled="detachProcessActive"
			@click="detachUser"
		>
			Detach user
		</button>
	</div>
</template>

<script lang="ts" src="./delete-detach-user-block.ts"></script>
<style lang="scss" src="./delete-detach-user-block.scss"></style>
