import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import UserDetailsForm from '@/presentation/components/user-details-form/user-details-form.vue';
import UserDetailsBlock from '@/presentation/components/user-details-block/user-details-block.vue';
import DeleteDetachUserBlock from '@/presentation/components/delete-detach-user-block/delete-detach-user-block.vue';
import OrganisationUsersTableCode from '@/presentation/components/organisation-users-table/organisation-users-table.code';

export default defineComponent({
	components: {
		UserDetailsForm: UserDetailsForm as Component,
		UserDetailsBlock: UserDetailsBlock as Component,
		DeleteDetachUserBlock: DeleteDetachUserBlock as Component
	},
	setup: () => {
		const code = new OrganisationUsersTableCode();

		onMounted(code.mounted.bind(code));
		onUnmounted(code.unmounted.bind(code));

		watch(code.organisationUsers, code.initiateTableRefresh.bind(code));
		watch(code.selectedUserAsStored, code.initiateTableRefresh.bind(code));

		return {
			selectedUserAsStored: code.selectedUserAsStored,
			selectedUserForDisplayPurposes: code.selectedUserForDisplayPurposes,
			topGrid: code.topGrid,
			bottomGrid: code.bottomGrid,
			topOrganisationUsers: code.topOrganisationUsers,
			bottomOrganisationUsers: code.bottomOrganisationUsers,
			shouldSecondTableBeShown: code.shouldSecondTableBeShown,
			detailsOfSelectedUser: code.detailsOfSelectedUser,
			resetSelectedUser: code.resetSelectedUser.bind(code)
		};
	}
});
