import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { computed } from 'vue';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import userDatePresenter from '@/presenters/user-date-presenter';
import store from '@/store';

export default class CustomComponentActions implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;
	private editElement: HTMLElement | null = null;
	private editClickListener: () => void;

	private user = computed((): DetailedUserDto | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as DetailedUserDto;
	});

	private selectedUser = computed((): number => {
		return store.getters.selectedUser();
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.editClickListener = this.onEditClicked.bind(this);
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		this.addTextElement();
		this.addIconWrapper();
	}

	public destroy(): void {
		this.editElement?.removeEventListener('click', this.editClickListener);
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addTextElement(): void {
		const element = document.createElement('p');
		element.innerHTML = `Last login: ${userDatePresenter.retrieveLastLoginDateStringForUser(this.user.value, true)}`;

		this.outerWrapper.appendChild(element);
	}

	private addIconWrapper(): void {
		const elementIconWrapper = document.createElement('div');
		elementIconWrapper.className = 'OrganisationUsersTableIconWrapper ActionsWrapper';

		if (this.selectedUser.value !== this.user.value?.id) {
			this.editElement = document.createElement('i');
			const deleteIcon = document.createElement('i');
			const detachIcon = document.createElement('i');
			this.editElement.className = 'OrganisationUsersTableIcon lnr-pencil';
			this.editElement.addEventListener('click', this.editClickListener);
			deleteIcon.className = 'OrganisationUsersTableIcon OrganisationIconDisabled TableHintIcon lnr-trash2';
			detachIcon.className = 'OrganisationUsersTableIcon OrganisationIconDisabled TableHintIcon lnr-unlink';
			elementIconWrapper.appendChild(this.editElement);
			elementIconWrapper.appendChild(deleteIcon);
			elementIconWrapper.appendChild(detachIcon);
		}

		this.outerWrapper.appendChild(elementIconWrapper);
	}

	private onEditClicked(): void {
		if (!this.user.value?.id) {
			return;
		}

		store.mutations.setSelectedUser(this.user.value.id);
	}
}
