import { reactive } from 'vue';

import { Routes } from '@/models/router/router.interface';
import { IState } from '@/models/store/state.interface';
import { ModalErrorStates } from '@/models/modal/modal.enum';

const state: any = reactive({
	modalConfirmationState: ModalErrorStates.INACTIVE,
	modalErrorState: ModalErrorStates.INACTIVE,
	organisationDetails: null,
	organisationSuccessMessage: '',
	organisationUsers: [],
	organisationUsersSorted: [],
	router: {
		current: Routes.USER_ACTIONS,
		props: {
			id: 0
		}
	},
	selectedUser: 0,
	standAloneLogin: false
});

export default state as IState;
