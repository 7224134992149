<template>
	<form
		class="UserDetailsForm ToggleBase"
		:key="`userDetailsForm_${resetCount}`"
	>
		<div class="UserContactDetailsWrapper">
			<div v-if="shouldOrganisationSelectorBeShown">
				<label class="UserContactDetailsLabel">
					Organisation
				</label>
				<SearchInput
					:type="searchTypeOrganisation"
					@valueSelected="setSelectedOrganisation"
				/>
			</div>
			<div v-for="property in contactDetailsProperties">
				<label
					class="UserContactDetailsLabel"
					:for="property.elementId"
				>
					{{ property.label }}
				</label>
				<input
					class="UserContactDetailsRow"
					:type="property.type"
					:id="property.elementId"
					:required="property.required"
					:value="property.value"
					@input="property.updateMethod"
				/>
			</div>
		</div>
		<div
			v-if="validationMessage"
			class="ValidationMessage"
		>
			{{ validationMessage }}
		</div>
		<div class="UserRightsDetailsWrapper">
			<div
				v-for="property in userRightsProperties"
				class="UserRightsDetailsRow"
			>
				<input
					type="checkbox"
					class="Toggle ToggleLight"
					:checked="property.value"
				/>
				<label
					class="ToggleButton"
					@click="property.toggleMethod"
				></label>
				<span
					class="ToggleStatus UserRightsDetailsLabel"
					:data-on="property.labelChecked"
					:data-off="property.labelUnchecked"
				>
					&nbsp;
				</span>
			</div>
		</div>
		<input
			type="button"
			class="ChampionButton"
			:value="isEdit ? 'Save Changes' : 'Create'"
			:disabled="isSubmitDisabled"
			@click="saveUser"
		/>
		<button
			type="button"
			class="HelperButton UsersManagementHelperButton"
			@click="updateFormValues(true)"
		>
			Cancel
		</button>
	</form>
</template>

<script lang="ts" src="./user-details-form.ts"></script>
<style lang="scss" src="./user-details-form.scss"></style>
