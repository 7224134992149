import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';

class UserDatePresenter {
	public retrieveLastLoginDateStringForUser(user: DetailedUserDto | undefined, shortString: boolean): string {
		if (!user?.lastLogin) {
			return 'never';
		}

		const result = (user.lastLogin as unknown as string).slice(0, shortString ? 10 : 19);
		if (shortString) {
			return result;
		}

		return result.replace('T', ' ');
	}
}

export default new UserDatePresenter();
