<template>
	<div class="UsersManagementPageBlock">
		<OrganisationDetailsBlock />

		<div class="UsersManagementContentBlock">
			<h2 class="UsersManagementTitle TextHighlighted">
				User rights on this entity
			</h2>

			<SearchInput :type="searchTypeUser" />
			<Accordion
				buttonText="Add a new user to this entity"
				:isOpen="isNewUserAccordionOpen"
				@toggleAccordion="toggleNewUserAccordion"
			>
				<UserDetailsForm
					:isVisible="isNewUserAccordionOpen"
					:isEdit="false"
					@formClosed="toggleNewUserAccordion"
				/>
			</Accordion>

			<div class="UsersManagementSubtitle UsersCountText">
				The following <em class="TextHighlighted">{{ amountOfUsersText }}</em>
				have access to this entity
			</div>
			<OrganisationUsersTable />
		</div>

		<DeleteConfirmationModal v-if="isModalConfirmationStateDelete" />
	</div>
</template>

<style lang="scss" src="./organisation.scss"></style>
<script lang="ts" src="./organisation.ts"></script>
