import { createApp, h } from 'vue';
import { IModalManager } from '@/models/modal/modal-manager.interface';
import { ModalManager } from '@studyportals/modal';
import store from '@/store';
import App from '@/presentation/app.vue';
import LogIn from '@/stand-alone/log-in/log-in.vue';
import Bootstrapper from '@/bootstrapper';
import StylesheetLoader from '@/stylesheet-loader';
import rollbarOperator from '@/infrastructure/rollbar-operator';

const standAloneLogin = webpackDefinitions.STAND_ALONE_LOGIN === 'true';
const url = webpackDefinitions.VUE_APP_ICONSET_URL;

declare global {
	interface Window {
		hj: (action: string, name: string) => void;
		ModalManager: IModalManager;
		Rollbar?: {
			error: (message: Error | string) => void;
			warn: (message: Error | string) => void;
		};
	}
}

window.ModalManager = new ModalManager();

StylesheetLoader.loadStylesheet(url);

store.mutations.setStandAloneLogin(standAloneLogin);

const placeholderSelector = '#UAUsersManagementFrontendPlaceholder';

Bootstrapper.bootstrap()
	.then(() => {
		const app = createApp({
			render: () => {
				return h(App);
			}
		});

		rollbarOperator.handleVueErrorsAndWarnings(app);

		app.mount(placeholderSelector);
	})
	.catch((error: Error) => {
		if (!standAloneLogin || error.name !== 'NotAuthorizedException') {
			throw error;
		}

		const app = createApp({
			render: () => {
				return h(LogIn);
			}
		});

		app.mount(placeholderSelector);
	});
