import { EmitOptions } from '@/models/emit-options.enum';
import { SearchDropdownTypes } from '@/models/search.interface';

export default class SearchDropdownCode {
	constructor(
		private emit: (event: string, userId: number) => void,
		private readonly type: SearchDropdownTypes
	) { }

	public get notFoundText(): string {
		let entityWording = '';

		switch (this.type) {
			case SearchDropdownTypes.ORGANISATION:
				entityWording = 'Organisation';
				break;
			default:
				entityWording = 'User';
		}

		return `${entityWording} not found.`;
	}

	public onUserClick(userId: number): void {
		this.emit(EmitOptions.USER_CLICK, userId);
	}
}
