import { DetailedUserDto } from '@studyportals/campaign-management-api-interface/src/domain/users/detailed-user.dto';

interface ISortEvent {
	stopPropagation: () => void;
	shiftKey: boolean | undefined;
}

enum ProductTypes {
	HAS_TABLEAU_ACCESS = 'hasTableauAccess',
	HAS_ERT_ACCESS = 'hasERTAccess',
	HAS_SMT_ACCESS = 'hasSMTAccess'
}

type TProductLabels = {
	[key in ProductTypes]: string;
};

interface IParams {
	data: DetailedUserDto;
}

export { ISortEvent, ProductTypes, TProductLabels, IParams };
