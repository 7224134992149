import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "UserSearchDropdownContainer" }
const _hoisted_2 = {
  key: 0,
  class: "SpinnerContainer"
}
const _hoisted_3 = {
  key: 0,
  class: "NotFoundContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_SearchDropdownItem = _resolveComponent("SearchDropdownItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSpinner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Spinner)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.items.length && _ctx.searchTerm)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.notFoundText), 1 /* TEXT */))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createBlock(_component_SearchDropdownItem, {
                  key: item.id,
                  name: item.name,
                  linkId: item.linkId,
                  type: item.type,
                  primarySubtitle: item.primarySubtitle,
                  secondarySubtitle: item.secondarySubtitle,
                  onUserClick: _ctx.userClick
                }, null, 8 /* PROPS */, ["name", "linkId", "type", "primarySubtitle", "secondarySubtitle", "onUserClick"]))
              }), 128 /* KEYED_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}