import { computed, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { ModalConfirmationStates } from '@/models/modal/modal.enum';
import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import userPresenter from '@/presenters/user-presenter';
import store from '@/store';

export default class DeleteConfirmationModalCode {
	public confirmationProcessActive = ref(false);
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public nameOfSelectedUser = computed((): string => {
		const users = this.organisationUsers.value;
		const selectedUser = this.selectedUser.value;
		const relevantUser = users.find((user: DetailedUserDto) => user.id === selectedUser);
		return relevantUser ? relevantUser.fullName : '';
	});

	private organisationUsers = computed((): DetailedUserDto[] => {
		return store.getters.organisationUsers();
	});

	private selectedUser = computed((): number => {
		return store.getters.selectedUser();
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public async deleteUser(): Promise<void> {
		this.confirmationProcessActive.value = true;
		await userPresenter.deleteUser();
		this.confirmationProcessActive.value = false;
		this.closeModal();
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'ConfirmationModalWrapper',
			destroyOnClose: false,
			onClose: this.closeModal.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}

	public closeModal(): void {
		store.mutations.setModalConfirmationState(ModalConfirmationStates.INACTIVE);
	}
}
