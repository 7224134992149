import { defineComponent } from 'vue';
import LogOutCode from '@/stand-alone/log-out/log-out.code';

export default defineComponent({
	setup: () => {
		const code = new LogOutCode();

		return {
			hideWidget: code.hideWidget.bind(code),
			signOut: code.signOut.bind(code)
		};
	}
});
