import { Component } from 'vue';

enum Routes {
	USER_ACTIONS = 'user-actions',
	ORGANISATION = 'organisation'
}

interface IRouter {
	current: Routes;
	props: {
		id: number;
	};
}

type TRoutes = {
	[key in Routes]: Component;
};

export { IRouter, TRoutes, Routes };
