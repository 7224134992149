<template>
	<div class="OrganisationUsersTable">
		<div
			ref="topGrid"
			 :class="[
				'UsersTable',
				'ag-theme-material',
				topOrganisationUsers.length ? 'UsersTableWithContent' : ''
			]"
		></div>
		<Transition>
			<div
				class="UsersTableEditUserForm"
				v-show="selectedUserAsStored"
			>
				<UserDetailsBlock :user="detailsOfSelectedUser" />
				<UserDetailsForm
					:isVisible="selectedUserForDisplayPurposes !== 0"
					:isEdit="true"
					:user="detailsOfSelectedUser"
					@formClosed="resetSelectedUser"
				/>
				<DeleteDetachUserBlock />
			</div>
		</Transition>
		<div
			:style="shouldSecondTableBeShown ? '' : 'display: none;'"
			ref="bottomGrid"
			:class="[
				'UsersTable',
				'ag-theme-material',
				bottomOrganisationUsers.length ? 'UsersTableWithContent' : ''
			]"
		></div>
	</div>
</template>

<style lang="scss" src="./organisation-users-table.scss"></style>
<script lang="ts" src="./organisation-users-table.ts"></script>
