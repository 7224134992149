import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "modalContent",
  class: "ConfirmationModal"
}
const _hoisted_2 = { class: "ConfirmationText" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " Are you sure you want to delete " + _toDisplayString(_ctx.nameOfSelectedUser) + "? ", 1 /* TEXT */),
    _createElementVNode("button", {
      type: "button",
      class: "DriverButton",
      disabled: _ctx.confirmationProcessActive,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteUser && _ctx.deleteUser(...args)))
    }, " Delete ", 8 /* PROPS */, _hoisted_3),
    _createElementVNode("button", {
      type: "button",
      class: "HelperButton UsersManagementHelperButton",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
    }, " Cancel ")
  ], 512 /* NEED_PATCH */))
}