import { MinimalOrganisationDto, OrganisationsCampaignManagementAPIClient } from '@studyportals/campaign-management-api-interface';
import { ISearchResult, SearchDropdownTypes, SearchResultTypes } from '@/models/search.interface';
import { ModalErrorStates } from '@/models/modal/modal.enum';
import cookieHandler from '@/infrastructure/cookie-handler';
import rollbarOperator from '@/infrastructure/rollbar-operator';
import store from '@/store';

class OrganisationsPresenter {
	private client: OrganisationsCampaignManagementAPIClient;
	private readonly tokenId: string;
	private readonly limit = 30;

	constructor() {
		this.client = new OrganisationsCampaignManagementAPIClient(webpackDefinitions.CAMPAIGN_MANAGEMENT_API_URL);
		this.tokenId = cookieHandler.retrieveTokenIdCookie();
	}

	public async prepareOrganisationDetails(organisationID: number): Promise<void> {
		try {
			const details = await this.client.getMinimalOrganisation(organisationID, this.tokenId);
			store.mutations.setOrganisationDetails(details);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.LOADING_ORGANISATION_DETAILS);
		}
	}

	public async fetchRelevantOrganisations(query: string, type: SearchDropdownTypes): Promise<ISearchResult[]> {
		let organisations: MinimalOrganisationDto[] = [];

		try {
			organisations = await this.client.searchOrganisations(query, this.tokenId, this.limit);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.SEARCH_ORGANISATIONS);
		}

		let searchResultType = SearchResultTypes.ORGANISATION;
		if (type === SearchDropdownTypes.COMBINED_SEARCH) {
			searchResultType = SearchResultTypes.ORGANISATION_FOR_COMBINED_SEARCH;
		}

		return organisations.map((organisation) => ({
			name: organisation.name,
			linkId: organisation.id,
			type: searchResultType,
			primarySubtitle: `ID ${organisation.id}`
		}));
	}
}

export default new OrganisationsPresenter();
