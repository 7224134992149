enum SearchDropdownTypes {
	USER = 'user',
	ORGANISATION = 'organisation',
	COMBINED_SEARCH = 'combinedSearch'
}

enum SearchResultTypes {
	USER = 'user',
	ORGANISATION = 'organisation',
	ORGANISATION_FOR_COMBINED_SEARCH = 'organisationForCombinedSearch',
	USER_FOR_COMBINED_SEARCH = 'userForCombinedSearch',
	UNLINKED_USER_FOR_COMBINED_SEARCH = 'unlinkedUserForCombinedSearch'
}

interface ISearchResult {
	name: string;
	type: SearchResultTypes;
	linkId?: number;
	primarySubtitle?: string;
	secondarySubtitle?: string;
}

export { ISearchResult, SearchDropdownTypes, SearchResultTypes };
