import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { computed } from 'vue';

export default class CustomComponentName implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;
	private emailElement: HTMLElement | null = null;
	private emailCopyListener: () => void;

	private user = computed((): DetailedUserDto | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as DetailedUserDto;
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.emailCopyListener = this.onEmailCopied.bind(this);
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		this.addTextElement();
		this.addIconWrapper();
	}

	public destroy(): void {
		this.emailElement?.removeEventListener('click', this.emailCopyListener);
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addTextElement(): void {
		const elementName = document.createElement('p');
		elementName.innerHTML = this.user.value?.fullName || '';
		this.outerWrapper.appendChild(elementName);
	}

	private addIconWrapper(): void {
		this.emailElement = document.createElement('i');
		this.emailElement.className = 'OrganisationUsersTableIcon lnr-envelope';
		this.emailElement.addEventListener('click', this.emailCopyListener);

		const elementIconWrapper = document.createElement('div');
		elementIconWrapper.appendChild(this.emailElement);
		this.outerWrapper.appendChild(elementIconWrapper);
	}

	private onEmailCopied(): void {
		if (!this.user.value?.email || !this.emailElement) {
			return;
		}

		void this.copyValue(this.user.value.email, this.emailElement);
	}

	private async copyValue(value: string, element: HTMLElement): Promise<void> {
		await navigator.clipboard.writeText(value);

		element.classList.add('IconValueCopied');
		setTimeout(() => {
			element.classList.remove('IconValueCopied');
		}, 1000);
	}
}
