<template>
	<div
		:class="[
			'UserSearchDropdownItemContainer',
			{ SearchItemContainerDisabled: isTypeUnlinkedUserForCombinedSearch }
		]"
		@click="onClick"
	>
		<div class="UserSearchDropdownItemRow">
			<span :class="[
				'Title',
				{ OrganisationTitleForCombinedSearch: isTypeUniversityForCombinedSearch }
			]">
				{{ name }}
			</span>
			<span
				v-if="isTypeUnlinkedUserForCombinedSearch"
				class="SearchItemUnlinkedMessage"
			>
				User is not linked to an entity.
			</span>
		</div>
		<div class="UserSearchDropdownItemRow">
			<span
				v-if="primarySubtitle"
				class="SearchItemSubtitle"
			>
				{{ primarySubtitle }}
			</span>
			<span
				v-if="secondarySubtitle"
				class="SearchItemSubtitle Secondary"
			>
				{{ secondarySubtitle }}
			</span>
		</div>
	</div>
</template>

<script lang="ts" src="./search-dropdown-item.ts" />
<style lang="scss" src="./search-dropdown-item.scss" />
