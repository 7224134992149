import { Routes } from '@/models/router/router.interface';
import store from '@/store';

class InitialRoutePresenter {
	private get defaultRoute(): Routes {
		return Routes.USER_ACTIONS;
	}

	private get idFromUrl(): number {
		const query = this.separateStringAndRetrieveHalf(window.location.href, '?');
		const queryParams = new URLSearchParams(query);
		const idAsString = queryParams.get('id');
		if (idAsString === null) {
			return 0;
		}
		const idAsNumber = parseInt(idAsString);
		if (isNaN(idAsNumber)) {
			return 0;
		}

		return idAsNumber;
	}

	private get routeToShow(): Routes {
		const microserviceUrlPart = this.separateStringAndRetrieveHalf(window.location.href, '#');
		const querylessUrlPart = this.separateStringAndRetrieveHalf(microserviceUrlPart, '?', false);
		if (!querylessUrlPart) {
			return this.defaultRoute;
		}

		const initialRoute = querylessUrlPart.replace('/', '');
		const allRoutes: string[] = Object.values(Routes);
		if (!allRoutes.includes(initialRoute)) {
			// Invalid route name, so bail out.
			return this.defaultRoute;
		}

		return initialRoute as Routes;
	}

	public prepareForRoutingActions(): void {
		// Ensure that initially, the right component is shown.
		this.showComponentInLineWithCurrentRoute();
		// Ensure that, when navigation is triggered outside of the microservice, the right component is shown.
		this.respondToExternalRouteChanges();
	}

	private showComponentInLineWithCurrentRoute(): void {
		store.mutations.setCurrentRoute(this.routeToShow, this.idFromUrl);
	}

	private respondToExternalRouteChanges(): void {
		window.addEventListener('hashchange', this.showComponentInLineWithCurrentRoute.bind(this));
	}

	private separateStringAndRetrieveHalf(stringToSeparate: string, separator: string, useEndOfUrl = true): string {
		const parts = stringToSeparate.split(separator);
		if (useEndOfUrl && parts.length < 2) {
			return '';
		}

		return useEndOfUrl ? parts[1] : parts[0];
	}
}

export default new InitialRoutePresenter();
