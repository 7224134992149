import { computed, ref, Ref } from 'vue';
import { ISearchResult, SearchDropdownTypes } from '@/models/search.interface';
import { SearchUserDto } from '@studyportals/campaign-management-api-interface/src/domain/users/search-users.dto';
import { MinimalOrganisationDto } from '@studyportals/campaign-management-api-interface';
import { EmitOptions } from '@/models/emit-options.enum';
import { Routes } from '@/models/router/router.interface';
import userPresenter from '@/presenters/user-presenter';
import organisationsPresenter from '@/presenters/organisations-presenter';
import store from '@/store';

export default class SearchInputCode {
	public searchInput: Ref<HTMLInputElement | null> = ref(null);
	public searchTerm: Ref<string> = ref('');
	public searchResults = ref<ISearchResult[]>([]);
	public showDropdown = ref<boolean>(false);
	public searchWrapper = ref<HTMLDivElement | null>(null);
	public showSpinner = ref<boolean>(false);

	public get isSearchTypeOrganisation(): boolean {
		return this.type === SearchDropdownTypes.ORGANISATION;
	}

	public get isSearchTypeUser(): boolean {
		return this.type === SearchDropdownTypes.USER;
	}

	public get isSearchTypeCombinedSearch(): boolean {
		return this.type === SearchDropdownTypes.COMBINED_SEARCH;
	}

	public get placeholder(): string {
		if (this.isSearchTypeOrganisation) {
			return 'Search by organisation name / ID';
		}

		if (this.isSearchTypeUser) {
			return 'Search for an existing user to add to this entity';
		}

		return 'Search by user name, email, organisation name or ID';
	}

	public searchResultsAsString = computed((): string => {
		return this.searchResults.value.map((item) => item.name).join(',');
	});

	private debounceTimeout: NodeJS.Timeout | number | null = null;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private boundHandleOutsideClick: (event: MouseEvent) => void;

	constructor(
		private type: SearchDropdownTypes,
		private emit: (eventName: string, id?: MinimalOrganisationDto) => void
	) {
		this.boundHandleOutsideClick = this.handleOutsideClick.bind(this);
	}

	public mounted(): void {
		document.addEventListener('click', this.boundHandleOutsideClick);
	}

	public unmounted(): void {
		document.removeEventListener('click', this.boundHandleOutsideClick);
	}

	public setSearchTerm(): void {
		if (this.searchInput.value === null) {
			return;
		}

		this.searchTerm.value = this.searchInput.value.value;

		if (this.searchTerm.value.trim() === '') {
			this.searchResults.value = [];
			return;
		}

		this.debounceSearch();
	}

	public onInputFocus(): void {
		this.showDropdown.value = true;
	}

	public async onUserClick(selectedValue: SearchUserDto | MinimalOrganisationDto): Promise<void> {
		if (!this.searchInput.value) {
			return;
		}

		this.showDropdown.value = false;
		this.searchTerm.value = '';
		this.searchResults.value = [];
		this.searchInput.value.focus();
		this.searchInput.value.value = '';

		switch (this.type) {
			case SearchDropdownTypes.USER:
				await userPresenter.attachUserToOrganisation(selectedValue.id, selectedValue.name);
				break;
			case SearchDropdownTypes.ORGANISATION:
				this.emit(EmitOptions.VALUE_SELECTED, selectedValue);
				this.searchInput.value.value = selectedValue.name;
				break;
			case SearchDropdownTypes.COMBINED_SEARCH:
				this.navigateToOrganisationPage(selectedValue.id);
				break;
			default:
				throw Error('Invalid search type.');
		}
	}

	private debounceSearch(): void {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout as NodeJS.Timeout);
		}

		this.showSpinner.value = true;
		this.debounceTimeout = setTimeout(() => void this.delayedFunction(), 1000);
	}

	private async delayedFunction(): Promise<void> {
		if (this.searchTerm.value.trim() === '') {
			this.searchResults.value = [];
			this.showSpinner.value = false;
			return;
		}

		this.showDropdown.value = true;
		await this.setResults();
		this.showSpinner.value = false;
	}

	private handleOutsideClick(event: MouseEvent): void {
		if (!this.searchWrapper.value || this.searchWrapper.value.contains(event.target as Node)) {
			return;
		}

		this.showDropdown.value = false;
	}

	private async setResults(): Promise<void> {
		switch (this.type) {
			case SearchDropdownTypes.USER:
				await this.setUserResults();
				break;
			case SearchDropdownTypes.ORGANISATION:
				await this.setOrganisationResults();
				break;
			case SearchDropdownTypes.COMBINED_SEARCH:
				await this.setResultsForCombinedSearch();
				break;
			default:
				throw Error('Invalid search type.');
		}
	}

	private async setUserResults(): Promise<void> {
		this.searchResults.value = await userPresenter.searchUsers(this.searchTerm.value);
	}

	private async setOrganisationResults(): Promise<void> {
		this.searchResults.value = await organisationsPresenter.fetchRelevantOrganisations(
			this.searchTerm.value,
			SearchDropdownTypes.ORGANISATION
		);
	}

	private async setResultsForCombinedSearch(): Promise<void> {
		let latestResults: ISearchResult[] = [];
		this.searchResults.value = [];

		await Promise.all([
			this.setOrganisationResultsForCombinedSearch()
				.then((response) => (latestResults = response.concat(latestResults))),
			this.setUserResultsForCombinedSearch()
				.then((response) => (latestResults = latestResults.concat(response)))
		]);

		this.searchResults.value = latestResults;
	}

	private async setOrganisationResultsForCombinedSearch(): Promise<ISearchResult[]> {
		return await organisationsPresenter.fetchRelevantOrganisations(
			this.searchTerm.value,
			SearchDropdownTypes.COMBINED_SEARCH
		);
	}

	private async setUserResultsForCombinedSearch(): Promise<ISearchResult[]> {
		return await userPresenter.searchUsersForCombinedSearch(
			this.searchTerm.value
		);
	}

	public navigateToOrganisationPage(organisationId: number): void {
		store.mutations.setCurrentRoute(Routes.ORGANISATION, organisationId);
	}
}
