import { computed, ref } from 'vue';
import { ActionSelectionOptions } from '@/models/action-selection-options.enum';
import { SearchDropdownTypes } from '@/models/search.interface';
import { Routes } from '@/models/router/router.interface';
import store from '@/store';

export default class UserActionsCode {
	private selectedAccordion = ref(ActionSelectionOptions.NONE);

	public get accordionSelectionOptionFindOrEditUser(): ActionSelectionOptions {
		return ActionSelectionOptions.FIND_OR_EDIT_USER;
	}

	public get accordionSelectionOptionAddNewUser(): ActionSelectionOptions {
		return ActionSelectionOptions.ADD_NEW_USER;
	}

	public get searchTypeCombinedSearch(): SearchDropdownTypes {
		return SearchDropdownTypes.COMBINED_SEARCH;
	}

	public isFindOrEditUserAccordionOpen = computed((): boolean => {
		return this.selectedAccordion.value === ActionSelectionOptions.FIND_OR_EDIT_USER;
	});

	public isAddNewUserAccordionOpen = computed((): boolean => {
		return this.selectedAccordion.value === ActionSelectionOptions.ADD_NEW_USER;
	});

	public setSelectedOption(accordion: ActionSelectionOptions): void {
		if (this.selectedAccordion.value === accordion) {
			this.selectedAccordion.value = ActionSelectionOptions.NONE;
		} else {
			this.selectedAccordion.value = accordion;
		}
	}

	public navigateToOrganisationPage(organisationId: number): void {
		store.mutations.setCurrentRoute(Routes.ORGANISATION, organisationId);
	}
}
