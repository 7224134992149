import { defineComponent } from 'vue';
import { SearchResultTypes } from '@/models/search.interface';
import SearchDropdownItemCode from '@/presentation/components/search-input/dropdown-item/search-dropdown-item.code';

export default defineComponent({
	props: {
		name: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		linkId: {
			type: Number,
			required: false
		},
		primarySubtitle: {
			type: String,
			required: false
		},
		secondarySubtitle: {
			type: String,
			required: false
		}
	},
	setup: (props, context) => {
		const code = new SearchDropdownItemCode(
			context.emit,
			props.type as SearchResultTypes,
			props.name,
			props.linkId,
			props.primarySubtitle
		);

		return {
			isTypeUniversityForCombinedSearch: code.isTypeUniversityForCombinedSearch,
			isTypeUnlinkedUserForCombinedSearch: code.isTypeUnlinkedUserForCombinedSearch,
			primarySubtitle: props.primarySubtitle,
			secondarySubtitle: props.secondarySubtitle,
			onClick: code.onClick.bind(code)
		};
	}
});
