import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "DeleteDetachUserBlock" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "HelperButton UsersManagementHelperButton ButtonDeleteUser",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteUser && _ctx.deleteUser(...args)))
    }, " Delete user "),
    _createElementVNode("button", {
      type: "button",
      class: "HelperButton UsersManagementHelperButton ButtonDetachUser",
      disabled: _ctx.detachProcessActive,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.detachUser && _ctx.detachUser(...args)))
    }, " Detach user ", 8 /* PROPS */, _hoisted_2)
  ]))
}