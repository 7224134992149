const ACCORDION_ANIMATION_DURATION = 300;
const BUFFER_DURATION = 100;
const NOTIFICATION_DISPLAY_DURATION = 3000;
const NOTIFICATION_DISPLAY_DURATION_AFTER_ROUTING = 7000;

export {
	ACCORDION_ANIMATION_DURATION,
	BUFFER_DURATION,
	NOTIFICATION_DISPLAY_DURATION,
	NOTIFICATION_DISPLAY_DURATION_AFTER_ROUTING
};
