import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { TProductLabels } from '@/models/table.interface';
import { ProductTypes } from '@/models/table.interface';

class UserProductLabelsPresenter {
	public get productLabels(): TProductLabels {
		return {
			[ProductTypes.HAS_TABLEAU_ACCESS]: 'Client reporting',
			[ProductTypes.HAS_ERT_ACCESS]: 'ERT',
			[ProductTypes.HAS_SMT_ACCESS]: 'SMT'
		};
	}

	public retrieveProductLabelStringForUser(user: DetailedUserDto): string {
		let result = '';

		for (const productType in this.productLabels) {
			if (!user[productType as ProductTypes]) {
				continue;
			}

			const label = this.productLabels[productType as ProductTypes].toLowerCase();
			const connector = result.length > 0 ? '_' : '';
			const value = label.split(' ').join('-');
			result += `${connector}${value}`;
		}

		return result;
	}
}

export default new UserProductLabelsPresenter();
