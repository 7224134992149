<template>
	<div
		ref="modalContent"
		class="ErrorModal"
	>
		<p><i class="lnr-notification-circle ErrorModalIcon"></i></p>
		<p class="ErrorDetails">
			<template v-if="errorIsRelatedToOrganisationDetails">
				The details of this organisation could not be fetched.
			</template>
			<template v-else-if="errorIsRelatedToOrganisationUsers">
				The users that are attached to this organisation could not be fetched.
			</template>
			<template v-else-if="errorIsRelatedToCreateNewUser">
				The user could not be created.
			</template>
			<template v-else-if="errorIsRelatedToUpdateExistingUser">
				The user could not be updated.
			</template>
			<template v-else-if="errorIsRelatedToTriggerOnboardingFlow">
				The onboarding flow could not be triggered.
			</template>
			<template v-else-if="errorIsRelatedToSearchUsers">
				The users that match the provided search query could not be fetched.
			</template>
			<template v-if="errorIsRelatedToAttachUserToOrganisation">
				The user could not be attached to the organisation.
			</template>
			<template v-if="errorIsRelatedToDeleteUser">
				The user could not be deleted.
			</template>
			<template v-if="errorIsRelatedToDetachUser">
				The user could not be detached from the organisation.
			</template>
			<template v-if="errorIsRelatedToSearchOrganisations || errorIsRelatedToCombinedSearchUsers">
				The organisations that match your search could not be fetched.
			</template>
			Please reload the page. If the problem persists, please contact the Product Team.
		</p>
	</div>
</template>

<script lang="ts" src="./error-modal.ts"></script>
<style lang="scss" src="./error-modal.scss"></style>
