import { IParams } from '@/models/table.interface';
import { IEnrolmentTableFieldSettings } from '@/models/user-table-field-settings.interface';
import userProductLabelsPresenter from '@/presenters/user-product-labels-presenter';
import userDatePresenter from '@/presenters/user-date-presenter';

// NOTE: fits 8px grid
const columnWidthPxXL = 224;
const columnWidthPxL = 200;
const columnWidthPxM = 176;

export default [
	{
		field: 'fullName',
		headerName: 'Full name',
		headerTooltip: 'Full name',
		cellRenderer: 'CustomComponentName',
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		width: columnWidthPxXL,
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		autoHeight: true,
		tooltipField: 'fullName',
		tooltipComponent: 'CustomComponentNameTooltip'
	},
	{
		field: 'role',
		headerName: 'Role',
		headerTooltip: 'Role',
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		width: columnWidthPxL,
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		autoHeight: true,
		tooltipField: 'role'
	},
	{
		field: 'activeCampaigns.0',
		headerName: 'Campaigns',
		headerTooltip: 'Campaigns',
		cellRenderer: 'CustomComponentCampaigns',
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		width: columnWidthPxM,
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		autoHeight: true
	},
	{
		field: '',
		headerName: 'Products',
		headerTooltip: 'Products',
		cellRenderer: 'CustomComponentProducts',
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		width: columnWidthPxM,
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		autoHeight: true,
		valueGetter: (params: IParams): string => userProductLabelsPresenter.retrieveProductLabelStringForUser(params.data)
	},
	{
		field: 'lastLogin',
		headerName: 'Actions',
		headerTooltip: 'Actions',
		cellRenderer: 'CustomComponentActions',
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		width: columnWidthPxM,
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		autoHeight: true,
		valueGetter: (params: IParams): string => userDatePresenter.retrieveLastLoginDateStringForUser(params.data, true)
	}
] as IEnrolmentTableFieldSettings[];
