import { ICellRendererComp, ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import { ISortEvent } from '@/models/table.interface';
import store from '@/store';

export default class CustomComponentHeader implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private elementIconSortUp: HTMLElement;
	private elementIconSortDown: HTMLElement;
	private params: (ICellRendererParams & IHeaderParams) | null = null;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private rotateSortOptionListener: (event: ISortEvent) => void;
	private sortChangedListener: () => void;
	private sortOption: 'asc' | 'desc' | null = null;

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.outerWrapper.classList.add('CustomComponentHeaderOuterWrapper');
		this.rotateSortOptionListener = this.onRotateSortOptionRequested.bind(this);
		this.sortChangedListener = this.onSortChanged.bind(this);
		this.elementIconSortUp = document.createElement('i');
		this.elementIconSortDown = document.createElement('i');
	}

	public init(params: ICellRendererParams & IHeaderParams): void {
		this.params = params;

		this.addTextElement();
		this.addIconWrapper();

		this.outerWrapper.addEventListener('click', this.rotateSortOptionListener);
		this.params?.column.addEventListener('sortChanged', this.sortChangedListener);
	}

	public destroy(): void {
		this.outerWrapper.removeEventListener('click', this.rotateSortOptionListener);
		this.params?.column.removeEventListener('sortChanged', this.sortChangedListener);
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addTextElement(): void {
		const elementName = document.createElement('p');
		elementName.className = 'CustomComponentHeaderTitle';
		elementName.innerHTML = this.params?.displayName || '';
		this.outerWrapper.appendChild(elementName);
	}

	private addIconWrapper(): void {
		this.elementIconSortUp = document.createElement('i');
		this.elementIconSortUp.className = 'CustomComponentHeaderSortingIcon lnr-chevron-up';

		this.elementIconSortDown = document.createElement('i');
		this.elementIconSortDown.className = 'CustomComponentHeaderSortingIcon lnr-chevron-down';

		const elementIconWrapper = document.createElement('div');
		elementIconWrapper.appendChild(this.elementIconSortUp);
		elementIconWrapper.appendChild(this.elementIconSortDown);
		this.outerWrapper.appendChild(elementIconWrapper);
	}

	private onSortChanged(): void {
		this.elementIconSortUp.classList.remove('Hidden');
		this.elementIconSortDown.classList.remove('Hidden');

		if (this.params?.column.isSortAscending()) {
			this.elementIconSortUp.classList.add('Hidden');
			this.sortOption = 'asc';
		} else if (this.params?.column.isSortDescending()) {
			this.elementIconSortDown.classList.add('Hidden');
			this.sortOption = 'desc';
		} else {
			this.sortOption = null;
		}

		document.dispatchEvent(new CustomEvent('UsersTableSortChanged'));
		// Unselect the user just in case; the position where the user details are shown might not be correct anymore after the change.
		store.mutations.setSelectedUser(0);
	}

	private onRotateSortOptionRequested(event: ISortEvent): void {
		event.stopPropagation();
		const options: ('asc' | 'desc' | null)[] = [null, 'asc', 'desc'];
		const indexOfCurrentOption = options.indexOf(this.sortOption);
		const indexOfNextOption = indexOfCurrentOption + 1;
		const nextOption = indexOfNextOption < options.length ? options[indexOfNextOption] : options[0];
		this.params?.setSort(nextOption, event.shiftKey);
	}
}
