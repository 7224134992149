import { defineComponent, Ref, toRef } from 'vue';
import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import UserDetailsBlockCode from '@/presentation/components/user-details-block/user-details-block.code';

export default defineComponent({
	props: {
		user: { type: Object, required: false }
	},
	setup: (props) => {
		const user = toRef(props, 'user');

		const code = new UserDetailsBlockCode(user as Ref<DetailedUserDto | undefined>);

		return {
			selectedUserName: code.selectedUserName,
			selectedUserRole: code.selectedUserRole,
			selectedUserEmail: code.selectedUserEmail,
			productTags: code.productTags,
			lastLoginDateStringLong: code.lastLoginDateStringLong,
			lastLoginDateStringShort: code.lastLoginDateStringShort,
			closeUserDetailsForm: code.closeUserDetailsForm.bind(code)
		};
	}
});
