import { defineComponent, onMounted, onUnmounted } from 'vue';
import DeleteConfirmationModalCode from '@/presentation/components/delete-confirmation-modal/delete-confirmation-modal.code';

export default defineComponent({
	setup: () => {
		const code = new DeleteConfirmationModalCode();

		onMounted(code.mounted.bind(code));
		onUnmounted(code.unmounted.bind(code));

		return {
			confirmationProcessActive: code.confirmationProcessActive,
			modalContent: code.modalContent,
			nameOfSelectedUser: code.nameOfSelectedUser,
			closeModal: code.closeModal.bind(code),
			deleteUser: code.deleteUser.bind(code)
		};
	}
});
