import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "UserSearchDropdownItemRow" }
const _hoisted_2 = {
  key: 0,
  class: "SearchItemUnlinkedMessage"
}
const _hoisted_3 = { class: "UserSearchDropdownItemRow" }
const _hoisted_4 = {
  key: 0,
  class: "SearchItemSubtitle"
}
const _hoisted_5 = {
  key: 1,
  class: "SearchItemSubtitle Secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
			'UserSearchDropdownItemContainer',
			{ SearchItemContainerDisabled: _ctx.isTypeUnlinkedUserForCombinedSearch }
		]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass([
				'Title',
				{ OrganisationTitleForCombinedSearch: _ctx.isTypeUniversityForCombinedSearch }
			])
      }, _toDisplayString(_ctx.name), 3 /* TEXT, CLASS */),
      (_ctx.isTypeUnlinkedUserForCombinedSearch)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " User is not linked to an entity. "))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.primarySubtitle)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.primarySubtitle), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.secondarySubtitle)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.secondarySubtitle), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}